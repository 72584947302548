import { useEffect, useState } from 'react';

import { useUserSession } from '@/session/UserSession';
import { Interaction } from '@becomposable/common';
import { FullHeightLayout } from '@reactik/layout';
import { useLocation } from '@reactik/router';

import ExecutionRequestView from './ExecutionRequestView';
import ExecutionResultView from './ExecutionResultView';
import PlaygroundState from './PlaygroundState';

interface PlaygroundViewProps {
    interaction: Interaction;
}
export default function PlaygroundView({ interaction }: PlaygroundViewProps) {
    const { search } = useLocation();
    const { client } = useUserSession();
    const [pgState, setPgState] = useState<PlaygroundState>();


    useEffect(() => {
        const replayId = new URLSearchParams(search).get("replay_id");
        if (replayId) {
            console.log("[PlaygroundView] Loading Replay: ", replayId);
            client.runs.retrieve(replayId).then((run) => {
                if (run.interaction.id !== interaction.id) {
                    console.error("Run's Interaction ID does not match the interaction ID");
                    return;
                }
                setPgState(new PlaygroundState(client, {
                    ...interaction,
                    result_schema: run.result_schema,
                    test_data: run.parameters
                }));
            });
        } else {
            setPgState(new PlaygroundState(client, interaction));
        }
    }, [interaction, search])

    return pgState ? (
        <PlaygroundState.Provider value={pgState}>
            <FullHeightLayout>
                <div className="w-full h-full flex items-stretch gap-x-4">
                    <div className="w-1/2 relative">
                        <ExecutionRequestView />
                    </div>
                    <div className="w-1/2 min-w-max relative">
                        <ExecutionResultView />
                    </div>
                </div>
            </FullHeightLayout>
        </PlaygroundState.Provider>
    ) : null;
}