import { ColumnLayout, ContentObjectItem } from "@becomposable/common";
import { Spinner, TBody, Table, useDropZone } from "@reactik/components";
import clsx from "clsx";
import { ChangeEvent, useMemo, useState } from "react";
import { ObjectSelection, useOptionalObjectSelection } from "./ObjectSelectionProvider";
import SelectObjectTypeModal from "./SelectObjectTypeModal";
import { TableColumn } from "./layout/TableColumn";

const defaultLayout: ColumnLayout[] = [
    { "name": "ID", "field": "id", "type": "string?slice=-7" },
    { "name": "Name", "field": ".", "type": "objectLink" },
    { "name": "Type", "field": "type.name", "type": "string" },
    { "name": "Status", "field": "status", "type": "string" },
    { "name": "Updated At", "field": "updated_at", "type": "date" }
];

interface ObjectTableProps extends _ObjectTableProps {
    onUpload?: (files: File[], type: string | null) => Promise<unknown>; // if defined, accept drag drop to upload
}
export default function ObjectsTable({ onUpload, ...others }: ObjectTableProps) {
    if (onUpload) {
        return (
            <ObjectTableWithDropZone {...others} onUpload={onUpload} />
        )
    } else {
        return <_ObjectsTable {...others} />
    }
}

interface ObjectTableWithDropZoneProps extends _ObjectTableProps {
    onUpload: (files: File[], type: string | null) => Promise<unknown>;
}
function ObjectTableWithDropZone({ onUpload, ...others }: ObjectTableWithDropZoneProps) {
    const [isLoading, setLoading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const onStartUpload = (files: File[]) => {
        setFiles(files);
    }
    const onDoUpload = (typeId?: string | null | undefined) => {
        setFiles([]);
        // if typeid is undefined we cancel the upload
        if (files.length > 0 && typeId !== undefined) {
            setLoading(true);
            onUpload(files, typeId).finally(() => setLoading(false));
        }
    }
    const dropZoneProps = useDropZone<HTMLDivElement>({ onUpload: onStartUpload });
    return (
        <div {...dropZoneProps} className="min-h-[400px] relative">
            <div className={clsx("bg-white bg-opacity-40 absolute inset-0 z-50 flex justify-center items-center",
                isLoading ? "block" : "hidden")}><Spinner size='xl' /></div>
            <_ObjectsTable {...others} />
            <SelectObjectTypeModal onClose={onDoUpload} isOpen={files.length > 0} actionLabel="Upload" title="Select Content Type">
                <div className="text-sm">
                    Select the associated Content Type, or let the system choose or generate the type based on the content.
                </div>
            </SelectObjectTypeModal>
        </div>
    )
}

interface _ObjectTableProps {
    objects: ContentObjectItem[];
    isLoading: boolean;
    layout?: ColumnLayout[];
    onRowClick?: (object: ContentObjectItem) => void;
    onSelectionChange?: (selection: ObjectSelection) => void;
}
function _ObjectsTable({ objects, layout = defaultLayout, isLoading, onRowClick, onSelectionChange }: _ObjectTableProps) {

    const selection = useOptionalObjectSelection();

    const _onSelectionChange = (object: ContentObjectItem, ev: ChangeEvent<HTMLInputElement>) => {
        if (selection) {
            const isShift = (ev.nativeEvent as any).shiftKey;
            const checked = ev.target.checked;
            if (!checked) {
                selection.remove(object.id);
            } else {
                selection.add(object)
                if (isShift) {
                    const index = objects.findIndex(obj => obj.id === object.id);
                    const prev = findPreviousSelected(objects, index, selection);
                    if (prev > -1 && prev < index - 1) {
                        const toSelect: ContentObjectItem[] = [];
                        for (let i = prev + 1; i < index; i++) {
                            toSelect.push(objects[i]);
                        }
                        selection.addAll(toSelect);
                    } else {
                        const next = findNextSelected(objects, index, selection);
                        if (next > -1 && next > index + 1) {
                            const toSelect: ContentObjectItem[] = [];
                            for (let i = index + 1; i < next; i++) {
                                toSelect.push(objects[i]);
                            }
                            selection.addAll(toSelect);
                        }
                    }
                }
            }
            onSelectionChange && onSelectionChange(selection);
        }
    }

    const toggleAll = (ev: ChangeEvent<HTMLInputElement>) => {
        if (selection) {
            const checked = ev.target.checked;
            if (!checked) { // remove all
                selection.removeAll();
            } else {
                selection.addAll(objects);
            }
        }
    }

    const columns = useMemo(() => {
        // avoid rendering empty layouts
        const actualLayout = layout.length > 0 ? layout : defaultLayout;
        return actualLayout.map(col => new TableColumn(col));
    }, [layout]);
    return (
        <Table className="w-full">
            <thead>
                <tr>
                    {selection && <th><input type="checkbox" onChange={toggleAll} /></th>}
                    {columns.map((col) => (
                        <th key={col.name}>{col.name}</th>
                    ))}
                </tr>
            </thead>
            <TBody isLoading={isLoading} columns={columns.length}>
                {
                    objects?.map((obj: ContentObjectItem) => {
                        return (
                            <tr key={obj.id} className='cursor-pointer hover:bg-gray-50 dark:hover:bg-slate-800' onClick={() => {
                                onRowClick && onRowClick(obj)
                            }}>
                                {selection &&
                                    <td onClick={ev => ev.stopPropagation()}>
                                        <input checked={selection.isSelected(obj.id)} type="checkbox"
                                            onChange={(ev: ChangeEvent<HTMLInputElement>) => _onSelectionChange(obj, ev)} />
                                    </td>
                                }
                                {columns.map((col, index) => col.render(obj, index))}
                            </tr>
                        )
                    })
                }
                {objects.length === 0 && <tr><td colSpan={columns.length + (selection ? 1 : 0)} className="text-center">No objects. Just drag and drop documents or images here to create content objects.</td></tr>}
            </TBody>
        </Table>
    )
}


function findPreviousSelected(objects: ContentObjectItem[], index: number, selection: ObjectSelection) {
    for (let i = index - 1; i >= 0; i--) {
        if (selection.isSelected(objects[i].id)) {
            return i;
        }
    }
    return -1;
}

function findNextSelected(objects: ContentObjectItem[], index: number, selection: ObjectSelection) {
    const length = objects.length;
    for (let i = index + 1; i < length; i++) {
        if (selection.isSelected(objects[i].id)) {
            return i;
        }
    }
    return -1;
}
