import clsx from "clsx";
import "./table.css";
import React from "react";

export function Table({ className, children, ...others }: React.HTMLProps<HTMLTableElement>) {
    return (
        <table className={clsx(className, "table-simple")} {...others}>
            {children}
        </table>
    )
}

export function RowSkeleton({ columns }: { columns: number }) {
    return (
        <tr>
            {Array(columns).fill(0).map((_, index) =>
                <td key={index}>
                    <div className="animate-pulse rounded h-5 bg-gray-200 dark:bg-gray-600"></div>
                </td>
            )}
        </tr>
    )
}

interface TBodySkeletonProps {
    isLoading?: boolean
    columns: number
    rows?: number
    children: React.ReactNode
}

export function TBody({ isLoading = false, columns, rows = 3, children }: TBodySkeletonProps) {
    return (
        <tbody>
            {isLoading ?
                Array(rows).fill(0).map((_, index) => <RowSkeleton columns={columns} key={index} />)
                : children
            }
        </tbody>
    )
}
