import PanelErrorBoundary from "@/errors/PanelErrorBoundary";
import EditorLayout from "@/modules/studio/features/prompt/editor/EditorLayout";
import { Tab, Tabs, TabsBar, TabsPanel } from "@reactik/components";
import { useCompositeState, useGetCompositeStateProperty } from "@reactik/hooks";
import PlaygroundState, { PlaygroundExecutionStatus } from "./PlaygroundState";
import PromptPanel from "./result/PromptPanel";
import ResultPanel from "./result/ResultPanel";
import StreamingPanel from "./result/StreamingPanel";


const getCurrentTab = (status: PlaygroundExecutionStatus) => {
    if (status === PlaygroundExecutionStatus.done) {
        return "result";
    } else {
        return "streaming";
    }
}

const getTabs = (playgroundState: PlaygroundState): Tab[] => {
    const status = playgroundState.status;
    const run = playgroundState.executionRun;
    const diableOtherTabs = status !== PlaygroundExecutionStatus.done;
    return [{
        name: "streaming",
        label: "Streaming",
        content: <StreamingPanel playgroundState={playgroundState} />
    },
    {
        name: "result",
        label: "Result",
        disabled: diableOtherTabs,
        content: <ResultPanel run={run} />
    },
    {
        name: "prompt",
        label: "Prompt",
        disabled: diableOtherTabs,
        content: <PromptPanel run={run} />
    }]
}


export default function ExecutionResultView() {
    const playgroundState = useCompositeState(PlaygroundState);
    const status = useGetCompositeStateProperty(playgroundState.executionStatus);
    return (
        <EditorLayout title="Execution Result">
            {/* result && getContent(result) */}
            <Tabs tabs={getTabs(playgroundState)} current={getCurrentTab(status)}>
                <TabsBar />
                <PanelErrorBoundary>
                    <TabsPanel />
                </PanelErrorBoundary>
            </Tabs>
        </EditorLayout>
    );
}
