import clsx from 'clsx';
import React, { ChangeEvent } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid';

import { Styles } from './styles.js';

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'value'> {
    value?: string;
    onChange?: (value: string) => void
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(({ value, onChange, className, type, ...others }: InputProps, ref) => {
    const _onChange = (ev: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(ev.target.value);
    };

    const _onClear = () => {
        onChange && onChange('');
    };

    return (
        <div className="w-full" style={{ position: 'relative', display: 'inline-block' }}>
            <input
                type={type || 'text'}
                value={value == null ? '' : value}
                onChange={_onChange}
                className={clsx('w-full', Styles.INPUT, className)}
                ref={ref}
                {...others}
            />
            {value && (
                <span
                    onClick={_onClear}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        fontSize: '16px'
                    }}
                >
                    <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-red-500 cursor-pointer" />
                </span>
            )}
        </div>
    );
})

export { Input };
