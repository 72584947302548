import { useAccessControlEntryRegistry } from '@/features/settings/iam/AccessControlEntryRegistry';
import SetRoleComponent from '@/features/settings/iam/SetRoleComponent';
import { useUserPermissions } from '@/session/permissions/UserPermissionsProvider';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Button, IndigoBadge, Modal, ModalBody, ModalTitle, PinkBadge, Table } from '@reactik/components';
import { useFlag } from '@reactik/hooks';

interface PermissionsTitleProps {
}
export function PermissionsTitle({ }: PermissionsTitleProps) {
    const registry = useAccessControlEntryRegistry();
    return (
        <div className="flex items-center w-full" >
            <div>User Roles</div>
            <RolesMappingModal variant="ghost"><QuestionMarkCircleIcon className="h-5 w-5" /></RolesMappingModal>
            <div className="ml-auto"><AddRoleModal refresh={() => registry.refresh()} /></div>
        </div>
    )
}


interface AddRoleModalProps {
    refresh: () => unknown;
}
export function AddRoleModal({ refresh }: AddRoleModalProps) {
    const { on, off, isOn } = useFlag(false);
    const onDone = () => {
        off();
        refresh();
    }
    return (
        <>
            <Button onClick={on}>Add Role</Button>
            <Modal onClose={off} isOpen={isOn} className="!max-w-[50vw]">
                <ModalTitle>Set User Role</ModalTitle>
                <ModalBody>
                    <SetRoleComponent onDone={onDone} />
                </ModalBody>
            </Modal>
        </>
    )
}

interface RolesMappingModalProps {
    children?: React.ReactNode;
    variant?: "primary" | "secondary" | "soft" | "ghost" | "unstyled";
}
export function RolesMappingModal({ variant, children }: RolesMappingModalProps) {
    const { on, off, isOn } = useFlag(false);
    const perms = useUserPermissions();
    return (
        <>
            <Button variant={variant} onClick={on}>{children}</Button>
            <Modal onClose={off} isOpen={isOn} className="!max-w-[50vw]">
                <ModalTitle>Roles to Permissions Mapping</ModalTitle>
                <ModalBody className="overflow-y-scroll max-h-[60vh] ">
                    <Table className='w-full'>
                        <thead>
                            <tr>
                                <td>Role</td>
                                <td>Permissions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                perms.system_roles.map((role) => {
                                    return (
                                        <tr key={role.name}>
                                            <td>{role.name}</td>
                                            <td>
                                                <div className="flex gap-2 flex-wrap">
                                                    {
                                                        role.permissions.map((perm) => {
                                                            return <IndigoBadge key={perm}>{perm}</IndigoBadge>
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
            </Modal>
        </>
    )
}