import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { AIModel } from "@llumiverse/core";
import { Button, useToast } from "@reactik/components";

interface ModelIdBoxProps {
    model: AIModel;
    max?: number;
}
export default function ModelIdBox({ model, max = 32 }: ModelIdBoxProps) {
    const toast = useToast();
    const shouldTruncate = model.id.length > max;
    const text = shouldTruncate ? model.id.slice(0, max) + '...' : model.id;
    const onCopy = () => {
        navigator.clipboard.writeText(model.id);
        toast({
            status: 'info',
            title: 'ID copied to clipboard',
            duration: 1000
        })
    }

    return (
        <div className="text-sm flex gap-x-2">
            <div className=''>ID:</div>
            <div className='group flex align-center gap-x-2 text-gray-600 dark:text-slate-200'>
                <div>{text}</div>
                <div className="ml-auto relative">
                    <Button variant="soft" className='absolute hidden group-hover:block -top-2' onClick={onCopy}>
                        <ClipboardDocumentIcon className='w-5 h-5' />
                    </Button>
                </div>
            </div >
        </div>
    )
}
