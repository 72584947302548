import Env from '@/env';
import { getAnalytics } from 'firebase/analytics';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig: FirebaseOptions = {
    apiKey: Env.firebase.apiKey,
    authDomain: Env.firebase.authDomain,
    projectId: "dengenlabs",
    appId: "1:265888598630:web:6e5e76c8ecde887e5afba7",
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);


function getFirebaseAuthToken(refresh?: boolean) {
    const user = firebaseAuth.currentUser;
    if (user) {
        return user.getIdToken(refresh).then(token => {
            return token;
        })
            .catch((err) => {
                console.error('Failed to get access token', err);
                return null;
            });
    } else {
        return Promise.resolve(null);
    }
}

function signOut() {
    return firebaseAuth.signOut();
}

export { analytics, firebaseApp, firebaseAuth, firebaseConfig, getFirebaseAuthToken, signOut };
