import { shortId } from "@/modules/store/text";
import { Link } from "@reactik/components";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);

const renderers: Record<string, (params?: URLSearchParams) => (value: any, index: number) => React.ReactNode> = {
    string(params?: URLSearchParams) {
        let transforms: ((value: string) => string)[] = [];
        if (params) {
            const slice = params.get('slice');
            if (slice) {
                transforms.push((value: string) => value.slice(parseInt(slice)));
            }
            if (params.has('upper')) {
                transforms.push((value: string) => value.toUpperCase());
            }
            if (params.has('lower')) {
                transforms.push((value: string) => value.toLowerCase());
            }
            if (params.has('capitalize')) {
                transforms.push((value: string) => value[0].toUpperCase() + value.substring(1));
            }
            if (params.has('ellipsis')) {
                transforms.push((value: string) => value + '...');
            }
        }
        return (value: any, index: number) => {
            let v: string;
            if (value) {
                v = String(value);
                if (transforms.length > 0) {
                    for (const t of transforms) {
                        v = t(v);
                    }
                }
            } else {
                v = '';
            }

            return <td key={index}>{v}</td>;
        }
    },

    number(params?: URLSearchParams) {
        let currency: string | undefined;
        let decimals: string | undefined;
        if (params) {
            currency = params.get("currency") || undefined;
            decimals = params.get("decimals") || undefined;
        }

        const digits = decimals ? parseInt(decimals) : 2;

        return (value: any, index: number) => {
            let v = new Intl.NumberFormat('en-US', { style: currency ? 'currency' : 'decimal', currency, maximumFractionDigits: digits }).format(value);
            return <td key={index}>{v}</td>;
        }
    },
    // value must be the object itself
    objectLink(params?: URLSearchParams) {
        let title = "title", underline = "hover";
        if (params) {
            title = params.get("title") || "title";
            underline = params.get("underline") || "hover";
        }
        return (value: any, index: number) => {
            return <td key={index}><Link underline={underline as any} href={`/objects/${value.id}`}>{value.properties?.[title] || value.name || shortId(value.id)}</Link></td>;
        }
    },
    typeLink(_params?: URLSearchParams) {
        return (value: any, index: number) => {
            return <td key={index}>{value?.name || 'n/a'}</td>;
        }
    },
    date(params?: URLSearchParams) {
        let method = 'format';
        let arg: string | undefined = 'LLL';
        if (params) {
            const localized = params.get('localized');
            if (localized) {
                arg = localized;
            } else {
                const relative = params.get('relative');
                if (relative) {
                    method = relative; // fromNow or toNow
                    arg = undefined;
                }
            }
        }
        return (value: any, index: number) => {
            return <td key={index}>{(dayjs(value) as any)[method](arg)}</td>;
        }
    },
}


export default renderers;