import clsx from 'clsx';
import { useState } from 'react';

import { useUserSession } from '@/session/UserSession';
import { Permission } from '@becomposable/common';
import { AcademicCapIcon, ArrowPathIcon, ChatBubbleLeftRightIcon, ChevronRightIcon, ChevronUpIcon, CloudIcon, Cog6ToothIcon, CommandLineIcon, HomeIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import { Styles } from '@reactik/components';
import { SidebarItem, SidebarSection } from '@reactik/layout';
import { Path, useLocation } from '@reactik/router';

import ObjectsIcon from './assets/objects.svg?react';
import TypesIcon from './assets/types1.svg?react';
import WorkflowIcon2 from './assets/workflow.svg?react';
import WorkflowIcon from './assets/workflow2.svg?react';
import SelectProjectNav from './features/project/SelectProjectNav';
import { AnyOf } from './session/permissions/helpers';
import { SecureSidebarItem } from './session/permissions/SecureSidebarItem';

const { isRootPath } = Path;

function matchPathPrefix(path: string, prefix: string) {
    return path.startsWith(prefix);
}

function matchPathPrefixes(path: string, ...prefixes: string[]) {
    return prefixes.some(prefix => path.startsWith(prefix));
}

function matchPathPrefixOrRoot(path: string, prefix: string) {
    return isRootPath(path) || path.startsWith(prefix);
}


interface AppMenuProps {
}
export function AppMenu({ }: AppMenuProps) {
    const [showContentTypes, setShowContentTypes] = useState(false);
    const path = useLocation().pathname;
    const { typeRegistry, project } = useUserSession();

    const objectsTools = typeRegistry && typeRegistry.types.length > 0 ?
        <button onClick={(e) => {
            setShowContentTypes(!showContentTypes)
            e.preventDefault();
        }} className="m-0 p-1 rounded-full text-gray-200 hover:bg-gray-200 hover:text-indigo-800">
            {showContentTypes ? <ChevronUpIcon className='w-4 h-4' />
                : <ChevronRightIcon className='w-4 h-4' />}
        </button> : null;

    return <>
        {

            <SidebarSection>
                <SelectProjectNav />
            </SidebarSection>
        }

        {
            project && <>
                <SidebarSection>
                    <SidebarItem current={matchPathPrefixOrRoot(path, '/dashboard')}
                        href="/studio/dashboard" icon={HomeIcon}>Dashboard</SidebarItem>
                </SidebarSection>

                {
                    project &&
                    <>
                        <SidebarSection title='Tasks'>
                            <SidebarItem id="menu-interactions" current={matchPathPrefix(path, "/studio/interactions")}
                                href="/studio/interactions" icon={ChatBubbleLeftRightIcon}>Interactions</SidebarItem>
                            <SidebarItem id="menu-prompts" current={matchPathPrefix(path, "/studio/prompts")}
                                href="/studio/prompts" icon={CommandLineIcon}>Prompts</SidebarItem>
                            <SidebarItem id="menu-runs" current={matchPathPrefix(path, "/studio/runs")}
                                href="/studio/runs" icon={RocketLaunchIcon}>Runs</SidebarItem>
                        </SidebarSection>

                        <SidebarSection title='Content'>
                            <SidebarItem id='menu-objets' current={matchPathPrefixes(path, "/store/objects")}
                                href="/store/objects" icon={ObjectsIcon}
                                tools={objectsTools}>Objects</SidebarItem>
                            <ContentTypesMenu isOpen={showContentTypes} />
                            <SidebarItem current={matchPathPrefix(path, "/store/types")}
                                href="/store/types" icon={TypesIcon}>Types</SidebarItem>
                        </SidebarSection>

                        <SidebarSection title='Workflow'>
                            <SidebarItem current={matchPathPrefix(path, "/store/executions")}
                                href="/store/executions" icon={ArrowPathIcon}>Executions</SidebarItem>
                            <SidebarItem id="menu-workflows" current={matchPathPrefix(path, "/store/rules")}
                                href="/store/rules" icon={WorkflowIcon}>Rules</SidebarItem>
                            <SidebarItem current={matchPathPrefix(path, "/store/workflows")}
                                href="/store/workflows" icon={WorkflowIcon2}>Processes</SidebarItem>
                        </SidebarSection>

                        <SidebarSection title='Models'>
                            <SidebarItem id="menu-environments" current={matchPathPrefix(path, "/studio/environments")}
                                href="/studio/environments" icon={CloudIcon}>Environments</SidebarItem>
                            <SidebarItem current={matchPathPrefix(path, "/studio/training")}
                                href="/studio/training" icon={AcademicCapIcon}>Fine Tuning</SidebarItem>
                        </SidebarSection>
                    </>
                }
                <SidebarSection isFooter>
                    <SecureSidebarItem permission={AnyOf(Permission.project_manage, Permission.account_manage)} current={matchPathPrefix(path, "/settings")}
                        href="/settings" id="menu-settings" icon={Cog6ToothIcon}>Settings</SecureSidebarItem>
                </SidebarSection>
            </>
        }

    </>
}

interface ContentTypesMenuProps {
    isOpen: boolean;
}
export default function ContentTypesMenu({ isOpen }: ContentTypesMenuProps) {
    const path = useLocation().pathname;
    const { typeRegistry } = useUserSession();
    const [filter, setFilter] = useState('');

    if (!isOpen || !typeRegistry || !typeRegistry.types.length) {
        return null;
    }

    let types = typeRegistry.types;
    if (filter) {
        types = types.filter(type => type.name.toLowerCase().includes(filter.toLowerCase()));
    }

    return isOpen && <div className='ml-2 pl-2 pt-2 border-l border-gray-300 border-solid'>
        <input autoFocus value={filter} onChange={(e: any) => setFilter(e.target.value.trim())} type='text' placeholder="Filter..."
            className={clsx(Styles.INPUT_UNSTYLED, "text-sm text-gray-200 placeholder:text-gray-300 bg-indigo-500 px-2 py-2 rounded-lg ")} />
        <div className="max-h-96 overflow-y-auto">
            {
                types.map(type => (
                    <SidebarItem key={type.id} current={matchPathPrefix(path, `/store/browse/${type.id}`)}
                        href={`/store/browse/${type.id}`}>{type.name}</SidebarItem>
                ))
            }
        </div>
    </div>
}
