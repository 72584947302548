const INPUT_UNSTYLED = "block m-0 p-0 w-full border-0 focus:outline-none focus:ring-0 bg-transparent"
const INPUT_NO_PADDING = "block sm:text-sm sm:leading-6 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-slate-600 dark:text-slate-50 dark:bg-slate-800 placeholder:text-gray-400 dark:placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 dark:focus:ring-indigo-800"
const INPUT = INPUT_NO_PADDING + " py-1.5";

const Styles = {
    INPUT_UNSTYLED,
    INPUT_NO_PADDING,
    INPUT
}

export { Styles }
