import { useState } from 'react';

import ProjectNamespaceWidget from '@/modules/studio/features/project/ProjectNamespaceWidget';
import { ProjectRef } from '@becomposable/common';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalTitle, useToast } from '@reactik/components';
import { useNavigate } from '@reactik/router';

import { useUserSession } from '../../session/UserSession';

interface CreateProjectModalProps {
    isOpen?: boolean;
    onClose: () => void;
}
export default function CreateProjectModal({ isOpen = false, onClose }: CreateProjectModalProps) {
    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalTitle>Create a Project</ModalTitle>
            <CreateProjectForm onClose={onClose} />
        </Modal>
    );
}

interface CreateProjectFormProps {
    onClose: () => void;
}
function CreateProjectForm({ onClose }: CreateProjectFormProps) {
    const navigate = useNavigate();
    const toast = useToast();
    const { switchProject, client } = useUserSession();
    const [projectName, setProjectName] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [namespace, setNamespace] = useState('');
    const [isNsValid, setIsNsValid] = useState(false);

    const onSubmit = () => {

        if (!isNsValid) {
            toast({
                status: 'error',
                title: 'Invalid namespace',
                description: 'Namespace must be at least 3 characters long and contain only alphanumeric characters, and [-_] ',
                duration: 5000
            });
            return;
        }

        const name = projectName?.trim() ?? namespace;

        setLoading(true);
        const promise = client.projects.create({
            name,
            namespace
        });
        promise.then((project: ProjectRef) => {
            console.log('created project', project, project.id);
            switchProject(project.id).then(() => {
                navigate(`/`);
            });
        }).catch((err) => {
            toast({
                status: 'error',
                title: 'Failed to create project',
                description: err.message,
                duration: 5000
            });
        }).finally(() => {
            setLoading(false);
            onClose();
        });
    };

    return (
        <>
            <ModalBody>
                <div className='mb-2'>Project Name</div>
                <Input className="w-full" value={projectName} onChange={setProjectName} />
                <ProjectNamespaceWidget namespace={namespace} onChange={setNamespace} setIsValid={setIsNsValid} />
            </ModalBody>
            <ModalFooter justify="end">
                <Button isDisabled={!isNsValid} isLoading={isLoading} onClick={onSubmit}>Create</Button>
            </ModalFooter>
        </>
    );
}
function textToId(value: string) {
    return value.toLowerCase().replace(/[^a-z0-9-_]/g, '-').replace(/-+/g, '-');
}
