


interface SettingsPanelProps {
    title: string | React.ReactNode
    children: React.ReactNode
    footer?: string | React.ReactNode
}

export default function SettingsPanel({ children, title, footer }: SettingsPanelProps) {

    return (
        <div className="mb-4 px-4 py-4 flex flex-col gap-4 rounded border bg-slate-50 dark:bg-inherit">
            <div className="font-semibold text-lg">{title}</div>
            {children}
            {footer &&
                <div className="border-t border-slate-300 dark:border-slate-200 flex flex-col-2 text-sm pt-4">
                    {footer}
                </div>}
        </div>

    )

}