interface BadgeProps {
    children: React.ReactNode;
    bgColor: string;
    textColor: string;
    ringColor: string;
    onClick?: () => void;
}
export function BadgeWithBorder({ children, bgColor, textColor, ringColor, onClick }: BadgeProps) {
    return (
        <span onClick={onClick} className={`inline-flex items-center rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor} ring-1 ring-inset ${ringColor}`}>
            {children}
        </span>
    )
}

interface GrayBadgeProps {
    children: React.ReactNode;
    onClick?: () => void;
}
export function GrayBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-gray-50' textColor="text-gray-600" ringColor='ring-gray-500/10'>{children}</BadgeWithBorder>
    )
}
export function RedBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-red-50' textColor="text-red-700" ringColor='ring-red-600/10'>{children}</BadgeWithBorder>
    )
}
export function YellowBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-yellow-50' textColor="text-yellow-800" ringColor='ring-red-600/20'>{children}</BadgeWithBorder>
    )
}
export function GreenBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-green-50' textColor="text-green-700" ringColor='ring-green-600/20'>{children}</BadgeWithBorder>
    )
}
export function BlueBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-blue-50' textColor="text-blue-700" ringColor='ring-blue-700/10'>{children}</BadgeWithBorder>
    )
}
export function IndigoBadge({ children, onClick }: GrayBadgeProps) {
    return (
        <BadgeWithBorder onClick={onClick} bgColor='bg-indigo-50' textColor="text-indigo-700" ringColor='ring-indigo-700/10'>{children}</BadgeWithBorder>
    )
}
export function PurpleBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-purple-50' textColor="text-purple-700" ringColor='ring-purple-700/10'>{children}</BadgeWithBorder>
    )
}
export function PinkBadge({ children }: GrayBadgeProps) {
    return (
        <BadgeWithBorder bgColor='bg-pink-50' textColor="text-pink-700" ringColor='ring-pink-700/10'>{children}</BadgeWithBorder>
    )
}

interface DotBadgeProps {
    children: React.ReactNode;
    className?: string; // ex fill-red-500, fill-yellow-500, fill-green-500, fill-blue-500,fill-indigo-500,fill-purple-500,fill-pink-500
}
export function DotBadge({ className, children }: DotBadgeProps) {
    return (
        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 dark:text-gray-200 ring-1 ring-inset ring-gray-200">
            <svg className={`h-1.5 w-1.5 ${className}`} viewBox="0 0 6 6" aria-hidden="true">
                <circle cx={3} cy={3} r={3} />
            </svg>
            {children}
        </span>
    )
}
