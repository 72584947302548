import { XCircleIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface MessageBoxProps {
    status: 'error' | 'info' | 'warning' | 'success'
    title: string
    children: React.ReactNode | React.ReactNode[]
}
export function MessageBox({ status, title, children }: MessageBoxProps) {

    let icon, titleColor, textColor, bgColor;
    switch (status) {
        case 'error': {
            icon = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            titleColor = "text-red-800";
            textColor = "text-red-700"
            bgColor = "bg-red-50 p-4";
            break;
        }
        case 'warning': {
            icon = <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            titleColor = "text-yellow-800";
            textColor = "text-yellow-700"
            bgColor = "bg-yellow-50 p-4";
            break;
        }
        case 'success': {
            icon = <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            titleColor = "text-green-800";
            textColor = "text-green-700"
            bgColor = "bg-green-50 p-4";
            break;
        }
        case 'info': {
            icon = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            titleColor = "text-blue-800";
            textColor = "text-blue-700"
            bgColor = "bg-blue-50 p-4";
            break;
        }
    }

    return (
        <div className={`rounded-md p-4 ${bgColor}`}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                <div className="ml-3">
                    <h3 className={`text-sm font-medium ${titleColor}`}>{title}</h3>
                    <div className={`mt-2 text-sm ${textColor}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div >
    )
}

export function ErrorBox({ title, children }: { title: string, children: React.ReactNode }) {
    return <MessageBox status="error" title={title}><pre>{children}</pre></MessageBox>
}

export function InfoBox({ title, children }: { title: string, children: React.ReactNode }) {
    return <MessageBox status="info" title={title}>{children}</MessageBox>
}

export function WarningBox({ title, children }: { title: string, children: React.ReactNode }) {
    return <MessageBox status="warning" title={title}>{children}</MessageBox>
}

export function SuccessBox({ title, children }: { title: string, children: React.ReactNode }) {
    return <MessageBox status="success" title={title}>{children}</MessageBox>
}
