import { OAuthProvider, signInWithRedirect } from "firebase/auth";
import { firebaseAuth } from "../../session/auth/firebase";

interface GoogleSignInButtonProps {
    redirectTo?: string;
}
export default function MicrosoftSignInButton({ redirectTo }: GoogleSignInButtonProps) {

    const signIn = () => {
        let redirectPath = redirectTo || window.location.pathname || '/';
        if (redirectPath[0] !== '/') {
            redirectPath = '/' + redirectPath;
        }
        const provider = new OAuthProvider('microsoft.com');
        provider.addScope('profile');
        provider.addScope('email');
        signInWithRedirect(firebaseAuth, provider);
    };

    return (
        <button
            onClick={signIn}
            className="px-4 py-2 w-52 border flex gap-2 border-slate-200 rounded-lg text-slate-700 dark:text-slate-300 hover:border-slate-400 hover:text-slate-900 dark:hover:text-slate-200 hover:shadow transition duration-150">
            <img className="w-6 h-6" src="https://learn.microsoft.com/en-us/entra/identity-platform/media/howto-add-branding-in-apps/ms-symbollockup_mssymbol_19.svg" loading="lazy" alt="microsoft logo" />
            <span className="text-sm font-semibold">Login with Microsoft</span>
        </button>
    );
}
