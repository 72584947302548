import SelectAIModel from "@/modules/studio/features/environment/SelectAIModel";
import SelectOrCreateEnvironment from "@/modules/studio/features/environment/SelectOrCreateEnvironment";
import { useUserSession } from "@/session/UserSession";
import {
    ExecutionEnvironmentRef
} from "@becomposable/common";
import { AIModel } from "@llumiverse/core";
import { NumberInput, Spinner } from "@reactik/components";
import { useCompositeState } from "@reactik/hooks";
import { useEffect, useState } from "react";
import PlaygroundState from "./PlaygroundState";


export default function RunConfigurationEditor() {
    const { client } = useUserSession();
    const [isLoaded, setIsLoaded] = useState(false);
    const playgroundState = useCompositeState(PlaygroundState);
    const interaction = playgroundState.interaction;
    const [temperature, setTemperature] = useState<number | undefined>(interaction.temperature ?? 0.7);
    const [model, setModel] = useState<string | undefined>(interaction.model);
    const [env, setEnv] = useState<ExecutionEnvironmentRef | undefined>(undefined);

    const onSelectEnv = (newEnv: ExecutionEnvironmentRef) => {
        if (newEnv.id === env?.id) return;
        setEnv(newEnv);
    };

    useEffect(() => {
        playgroundState.config = {
            temperature,
            model,
            environment: env?.id
        };
        playgroundState.env = env;
    }, [temperature, model, env, playgroundState]); // when playgroundState changes we need to update the config

    useEffect(() => {
        if (interaction.environment) {
            client.environments
                .retrieve(interaction.environment as string)
                .then((env) => {
                    setEnv(env);
                })
                .catch((err) => {
                    console.error("Failed to load execution environment", err);
                })
                .finally(() => setIsLoaded(true));
        } else {
            setIsLoaded(true);
        }
    }, [interaction.environment]);

    const setAIModel = (model?: AIModel) => {
        setModel(model?.id);
    };

    return isLoaded ? (
        <div>
            <div className="mb-4">
                <div className="mb-2 text-gray-500 font-semibold text-sm">
                    Select an execution environment
                </div>
                <SelectOrCreateEnvironment value={env} onChange={onSelectEnv} selectFirst />
            </div>
            <div className="mb-4 flex w-full space-x-4">
                <div className="w-3/6">
                    <div className="mb-2 text-gray-500 font-semibold text-sm">
                        Select a Model
                    </div>
                    {env && (
                        <SelectAIModel
                            models={env?.enabled_models}
                            selected={model}
                            onSelect={setAIModel}
                            env={env}
                            allowSearch={true}
                            size="sm"
                        />
                    )}
                </div>
                <div className="w-3/6">
                    <div className="mb-2 text-gray-500 font-semibold text-sm">
                        Temperature
                    </div>
                    <NumberInput
                        name="temperature"
                        value={temperature}
                        onChange={setTemperature}
                        className="w-full"
                        min={0}
                        max={1}
                        step={0.1}
                        noScroll
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="flex justify-center">
            <Spinner size="lg" />
        </div>
    );
}
