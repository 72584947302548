import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { HamburgerButton } from '@reactik/layout';

interface GenericPageNavHeaderProps {
    title: string | ReactElement;
    description?: string | ReactElement;
    actions?: ReactNode | ReactNode[];
    breadcrumbs?: ReactElement[]
    isCompact?: boolean
    children?: ReactNode
    className?: string
}

export default function GenericPageNavHeader({ className, children, title, description, actions, breadcrumbs, isCompact = false }: GenericPageNavHeaderProps) {
    return (
        <div>
            <div className={clsx(isCompact ? 'pb-0' : 'pb-4', 'bg-gray-50 dark:bg-inherit border-b border-solid border-gray-200 dark:border-slate-700 p-4 flex flex-col', className)}>
                <div className="w-full flex items-center place-content-between h-8">
                    <div className="flex items-center">
                        <HamburgerButton />
                        <nav className="flex-1 flex justify-start">
                            {breadcrumbs?.map((breadcrumb, index) => {
                                return (
                                    <div className="flex items-center dark:brightness-75" key={index}>
                                        {breadcrumb}
                                        {index < breadcrumbs.length - 1 && <ChevronRightIcon className="h-6" />}
                                    </div>
                                )
                            })}
                        </nav>
                    </div>
                    <div className="flex gap-x-4 items-center">{actions}</div>
                </div>
                <div className="w-full flex items-center">
                    <div>
                        <h1 className="text-2xl font-semibold">{title}</h1>
                        {description && <div className="text-sm text-gray-600 dark:text-slate-600 pr-20">{description}</div>}
                    </div>
                </div>
                {children && <div className="w-full flex items-center">{children}</div>}
            </div>
        </div>
    )
}