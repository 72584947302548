import type { JSONObject } from "@llumiverse/core";
import { useState } from 'react';

import { Button } from '@reactik/components';

import { JSONCode } from './JSONCode.js';
import { JSONView } from './JSONView.js';

const VIEW_JSON = "JSON";
const VIEW_TEXT = "Preview";

interface JSONDisplayProps {
    value: JSONObject;
    viewCode?: boolean;
}
export function JSONDisplay({ value, viewCode = false }: JSONDisplayProps) {
    const [_viewCode, setViewCode] = useState(viewCode);

    if (!value) {
        return (
            <pre className="whitespace-pre-wrap">No Data to display</pre>
        );
    }

    return (
        <div className='relative w-full'>
            <div className="absolute right-0 top-0">
                <Button variant='soft' onClick={() => setViewCode(!_viewCode)}>{_viewCode ? VIEW_TEXT : VIEW_JSON}</Button>
            </div>
            {_viewCode ? <JSONCode data={value} /> : <JSONView value={value} />}
        </div>
    )
}