import React from 'react';

interface PublicPageProps {
    children: React.ReactNode | React.ReactNode[];
}
export default function PublicPage({ children }: PublicPageProps) {
    return (
        <div className="bg-slate-100 dark:bg-slate-800 w-full h-full md:px-40 px-0">
            <div className="bg-white h-full py-4 px-10 relative">
                {children}
            </div>
        </div>
    )
}