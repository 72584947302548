import { ErrorBoundary } from '@datadog/browser-rum-react';
import { ErrorBoundaryProps } from './types';


export default function WidgetErrorBoundary({ children }: ErrorBoundaryProps) {

    return (
        <ErrorBoundary fallback={WidgetErrorFallback}>
            {children}
        </ErrorBoundary>
    )
}

export function WidgetErrorFallback({ error }: { error?: Error }) {

    console.log('WidgetError', error);

    return (
        <div className="text-sm">
            Sorry, this area cannot be loaded or rendered.
            {error?.message &&
                <pre>
                    {error.message}
                </pre>
            }
        </div>
    )
}