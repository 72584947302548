import WidgetErrorBoundary from "@/errors/WidgetErrorBoundary";
import { renderPrompt } from "@/modules/studio/utils/rendering";
import { Interaction, PromptSegmentDef, PromptTemplate } from "@becomposable/common";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import type { JSONObject } from "@llumiverse/core";
import { Button, Dropdown, MenuItem, Modal, ModalBody, ModalTitle } from "@reactik/components";
import { useCompositeState } from "@reactik/hooks";
import { GeneratedForm, ManagedObject } from "@reactik/json";
import { encodingForModel } from "js-tiktoken";
import { useEffect, useState } from "react";
import GenerateTestDataModal from "./GenerateTestDataModal";
import JSONEditorModal from "./JSONEditorModal";
import PlaygroundState from "./PlaygroundState";
import DocumentInput from "./docs/DocumentInput";

interface RunPayloadEditorProps {
    onChange?: () => void;
}
export default function RunPayloadEditor({ onChange }: RunPayloadEditorProps) {
    const playgroundState = useCompositeState(PlaygroundState);
    const payload = playgroundState.payload;
    const [showJsonEditorModal, setShowJsonEditorModal] = useState(false);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [showSchemaModal, setShowSchemaModal] = useState(false);
    const [showTestDataModal, setShowTestDataModal] = useState(false);
    const [formKey, setFormKey] = useState(0);
    const [estimatedTokenCount, setEstimatedTokenCount] = useState<number | undefined>(undefined);

    const onSetTestData = (data: any) => {
        payload.value = data;
        setFormKey(formKey + 1);
        onPayloadChange();
    }

    const onPayloadChange = () => {
        onChange && onChange();
        setEstimatedTokenCount(estimateTokenCount());
    }

    const estimateTokenCount = () => {
        try {
            const encoding = encodingForModel("gpt-4")
            const prompt = getRenderedPrompt();
            const token = encoding.encode(prompt);
            return token.length;
        } catch (err) {
            console.error(err);
            return undefined;
        }
    }

    const getRenderedPrompt = () => {
        try {

            const prompt = renderPrompt(playgroundState.interaction.prompts as PromptSegmentDef<PromptTemplate>[], payload.value as JSONObject);
            return JSON.stringify(prompt, null, 2);
        } catch (err) {
            console.error(err);
            return "";
        }
    }

    useEffect(() => {
        setEstimatedTokenCount(estimateTokenCount());
    }, []);

    return payload.schema.hasProperties() ? (
        <div className='mt-4'>
            <div className="flex items-center h-10 py-2 mb-4 border-b border-solid border-b-gray-200 dark:border-b-gray-500">
                <div className="text-md font-semibold">Parameters</div>
                <div className="ml-auto flex gap-x-2 items-center">
                    <WidgetErrorBoundary>
                        <div className="text-sm text-slate-500 dark:text-slate-400">Estimated Token Count: ~{estimatedTokenCount}</div>
                        <Button variant='ghost' onClick={() => setShowJsonEditorModal(true)}>Edit JSON</Button>
                        <Dropdown trigger={<EllipsisHorizontalIcon className='h-5 w-5 cursor-pointer' />}>
                            <MenuItem onClick={() => setShowSchemaModal(true)}>View JSON Schema</MenuItem>
                            <MenuItem onClick={() => setShowPromptModal(true)}>View Generated Prompt</MenuItem>
                            <MenuItem onClick={() => setShowTestDataModal(true)}>Generate Synthetic Data</MenuItem>
                        </Dropdown>
                    </WidgetErrorBoundary>
                </div>
            </div>

            <GeneratedForm key={formKey} object={payload}
                components={{
                    media: DocumentInput,
                    document: DocumentInput
                }}
                onChange={onPayloadChange}
            />
            <JSONEditorModal title="Edit JSON Payload" value={payload.value as JSONObject} isOpen={showJsonEditorModal} onClose={(value?: JSONObject) => {
                if (value) {
                    onSetTestData(value);
                }
                setShowJsonEditorModal(false)
            }} />
            <DisplaySchemaModal schema={payload.schema.schema} isOpen={showSchemaModal} onClose={() => setShowSchemaModal(false)} />
            <GenerateTestDataModal onSave={onSetTestData} interaction={playgroundState.interaction} isOpen={showTestDataModal} onClose={() => setShowTestDataModal(false)} />
            <PromptModal interaction={playgroundState.interaction} payload={payload} isOpen={showPromptModal} onClose={() => setShowPromptModal(false)} />
        </div>
    ) : null;
}

function DisplaySchemaModal({ schema, isOpen, onClose }: { schema: JSONObject; isOpen: boolean; onClose: () => void; }) {

    return (
        <Modal onClose={onClose} isOpen={isOpen} className='min-w-[60vw]'>
            <ModalTitle>Input Schema</ModalTitle>
            <ModalBody className='p-4 overflow-y-auto max-h-[80vh]'>
                <pre className="whitespace-pre-wrap">{JSON.stringify(schema, null, 2)}</pre>
            </ModalBody>
        </Modal>
    );

}

function PromptModal({ interaction, payload, isOpen, onClose }: { interaction: Interaction, payload: ManagedObject; isOpen: boolean; onClose: () => void; }) {
    let prompt, error;

    try {
        prompt = renderPrompt(interaction.prompts as PromptSegmentDef<PromptTemplate>[], payload.value as JSONObject);
    } catch (err: any) {
        error = err;
    }

    return (
        <Modal onClose={onClose} isOpen={isOpen} className='min-w-[60vw]'>
            <ModalTitle>Llumiverse Prompt</ModalTitle>
            <ModalBody className='p-4 overflow-y-auto max-h-[80vh]'>
                {error && <div className="text-red-600">{error.message}</div>}
                {prompt && <pre className="whitespace-pre-wrap">{JSON.stringify(prompt, null, 2)}</pre>}
            </ModalBody>
        </Modal>
    );

}
