import Env from '@/env';
import { SignupData, SignupPayload } from "@becomposable/common";
import { useSafeLayoutEffect } from "@reactik/hooks";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useUserSession } from "../../session/UserSession";
import { UserNotFoundError } from "../../session/auth/composable";
import useUXTracking from "../../session/useUXTracking";
import GitHubSignInButton from "./GitHubSignInButton";
import GoogleSignInButton from "./GoogleSignInButton";
import MicrosoftSignInButton from "./MicrosoftSigninButton";
import SignupForm from "./SignupForm";


interface SigninScreenProps {
    isNested?: boolean;
    allowedPrefix?: string;
}
export default function SigninScreen({ allowedPrefix, isNested = false }: SigninScreenProps) {
    const [allow, setAllow] = useState(false);
    useSafeLayoutEffect(() => {
        allowedPrefix && setAllow(window.location.href.startsWith(allowedPrefix));
    }, []);
    return allow ? null : <_SigninScreen isNested={isNested} />;
}


function _SigninScreen({ isNested = false }: SigninScreenProps) {
    const { isLoading, user, logout, authError } = useUserSession();
    const [collectSignupData, setCollectSignupData] = useState(false);
    const [signupData, setSignupData] = useState<SignupData | undefined>(undefined);
    const { signOut } = useUserSession();
    const { trackEvent } = useUXTracking();

    const onSignup = (data: SignupData, fbToken: string) => {
        console.log('Got Signup data', data);
        setSignupData(data);
        const payload: SignupPayload = {
            signupData: data,
            firebaseToken: fbToken
        };
        fetch(Env.endpoints.studio + '/auth/signup', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }).then((res) => {
            console.log('Signup successful', payload, res);

            trackEvent('sign_up');
            window.location.href = '/';
        });

    }

    const goToSignup = () => {
        setSignupData(undefined);
        setCollectSignupData(true);
    }

    const goBack = () => {
        console.log('Going back, signing out');
        setSignupData(undefined);
        setCollectSignupData(false);
        logout();
    }

    useEffect(() => {
        if (authError instanceof UserNotFoundError) {
            console.log('User not found, redirecting to signup');
            goToSignup();
        }
    }, [authError]);

    return !isLoading && !user ? (
        <div style={{ zIndex: 999998 }} className={clsx(isNested ? 'absolute' : 'fixed', 'inset-x-0 inset-y-0 bg-white dark:bg-slate-900 overflow-y-auto flex flex-col items-center justify-center')}>
            <div className=" text-gray-900 dark:text-slate-200">
                <h2 className="text-2xl font-bold text-center">
                    Log in or Sign up
                </h2>
            </div>
            {signupData &&
                <div className="my-6 dark:text-slate-200">
                    Need to make a change?{' '}
                    <button onClick={goToSignup} className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-200 hover:underline hover:cursor-auto">Go back</button>
                </div>
            }
            <div className="flex flex-col space-y-2">
                {collectSignupData ?
                    <SignupForm onSignup={onSignup} goBack={goBack} />
                    : <SigninPanel authError={authError} goToSignup={goToSignup} />
                }

            </div>
            <div className='flex gap-x-6 mt-6 justify-center text-slate-400 dark:text-slate-600'>
                <a href="https://composableprompts.com/privacy" className="text-sm text-slate-500">Privacy Policy</a>
                <a href="https://composableprompts.com/terms" className="text-sm text-slate-500">Terms of Service</a>
            </div>
        </div>
    ) : null;
}


function SigninPanel({ authError }: { authError?: Error, goToSignup: () => void }) {

    return (
        <div className="flex flex-col">
            <div className='flex mt-10 items-center flex-col'>
                <GoogleSignInButton />
                <GitHubSignInButton />
                <MicrosoftSignInButton />
            </div>
            {
                authError &&
                <div className="mt-8 text-center">
                    <div className='text-slate-900 dark:text-slate-200'>
                        Sorry, we have not been able to sign you in.<br />
                        Please try again or contact support@becomposable.com if it persists.
                        <pre className="mt-2">Error: {authError.message}</pre>
                    </div>
                </div>

            }
            <div className="max-w-2xl text-center mt-12 text-slate-900 dark:text-slate-200">
                First time here? No problem, it's free to try!<br />
                We'll just ask you a couple of questions next and you'll be on your way.
            </div>

        </div>
    )
}
