import { useUserSession } from "@/session/UserSession";
import { ExecutionEnvironmentRef } from "@becomposable/common";
import { AIModel } from "@llumiverse/core";
import { SelectBox } from "@reactik/components";
import { useFetch, useFetchOnce } from "@reactik/hooks";
import { useEffect, useState } from "react";


function optionLabel(option: ExecutionEnvironmentRef) {
    return option.name;
}

interface SelectEnvironmentProps {
    onChange: (env?: ExecutionEnvironmentRef) => void;
    isClearable?: boolean;
    ignoreVirtuals?: boolean;
    selectedEnvId?: string;
    disabled?: boolean;
}
export function SelectEnvironment({ isClearable = false, onChange, ignoreVirtuals = false, selectedEnvId, disabled = false }: SelectEnvironmentProps) {
    const { client } = useUserSession();
    const [value, setValue] = useState<ExecutionEnvironmentRef | undefined>();
    const { data: names } = useFetchOnce(() => client.environments.list().then(r => {

        if (selectedEnvId) {
            const env = r.find(e => e.id === selectedEnvId);
            if (env) {
                setValue(env);
            }
        }

        if (ignoreVirtuals) {
            return r.filter(e => !e.provider.startsWith('virtual_'));
        } else {
            return r;
        }
    }));

    const _onChange = (option: ExecutionEnvironmentRef | undefined) => {
        setValue(option);
        onChange(option);
    }

    useEffect(() => {
        if (selectedEnvId === undefined) {
            setValue(undefined);
        }
    }, [selectedEnvId]);

    return <SelectBox isClearable={isClearable} optionLabel={optionLabel} options={names || []} value={value} onChange={_onChange} placeholder='All Environments' filterBy="name" disabled={disabled} />
}

function modelOptionLabel(option: AIModel) {
    return option.name;
}

interface SelectModelProps {
    envId: string | undefined;
    onChange: (model?: AIModel) => void;
    isClearable?: boolean;
    selectedModelId?: string;
}
export function SelectModel({ envId, isClearable, onChange, selectedModelId }: SelectModelProps) {
    const { client } = useUserSession();
    const [value, setValue] = useState<AIModel | undefined>();
    const { data: models } = useFetch(async () => {

        if (!envId) {
            return [];
        } else {
            try {
                const env = await client.environments.retrieve(envId);
                if (selectedModelId) {
                    const model = env.enabled_models?.find(m => m.id === selectedModelId);
                    if (model) setValue(model);
                }
                return env.enabled_models || []
            } catch (e: any) {
                return [];
            }
        }

    }, [envId]);


    const _onChange = (option: AIModel | undefined) => {
        setValue(option);
        onChange(option);
    }

    return <SelectBox isClearable={isClearable} by="id" optionLabel={modelOptionLabel} options={models || []} value={value} onChange={_onChange} placeholder='All Models' />
}

interface SelectTrainingModelProps {
    env: ExecutionEnvironmentRef | undefined;
    onChange: (model?: AIModel) => void;
    isClearable?: boolean;
}
export function SelectTrainingModel({ env, isClearable, onChange }: SelectTrainingModelProps) {
    const { client } = useUserSession();
    const [value, setValue] = useState<AIModel | undefined>();
    const { data: models } = useFetch(async () => {
        if (!env) {
            return [];
        } else {
            try {
                return client.environments.listTrainableModels(env.id);
            } catch (e: any) {
                return [];
            }
        }
    }, [env?.id]);


    const _onChange = (option: AIModel | undefined) => {
        setValue(option);
        onChange(option);
    }

    return <SelectBox isClearable={isClearable} by="id" optionLabel={modelOptionLabel} options={models || []} value={value} onChange={_onChange} placeholder='All Models' />
}
