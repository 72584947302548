import { PromptSegmentDef, PromptSegmentDefType, PromptTemplate } from "@becomposable/common";
import { CompositeError, renderJsTemplate } from "@dglabs/jst";
import type { JSONObject } from "@llumiverse/core";
import { PromptSegment } from "@llumiverse/core";
import { JSONSchema4 } from "json-schema";

export interface SegmentPreview {
    error?: Error;
    title: string;
    content: string;
    segmentId?: string;
}


function renderChatSegment(segment: PromptSegmentDef<PromptTemplate>, data: JSONObject) {
    const chatKey = segment.configuration?.chatKey ?? 'chat';
    const chat = data[chatKey];
    let content;
    if (Array.isArray(chat)) {
        content = JSON.stringify(chat, undefined, 2);
    } else {
        content = `No "${chatKey}" property found on input data`;
    }
    return {
        title: "Chat history",
        content
    }
}

function renderTemplateSegment(segment: PromptSegmentDef<PromptTemplate>, data: JSONObject) {
    const schema = segment.template?.inputSchema || {};
    const content = renderTemplate(segment.template!.content, schema, data);
    return {
        title: "@" + segment.template!.role,
        content: content,
        segmentId: segment.template!.id
    }
}

export function renderSegments(segments: PromptSegmentDef<PromptTemplate>[], data: JSONObject): SegmentPreview[] {
    const out: SegmentPreview[] = [];
    for (const segment of segments) {
        if (segment.type === PromptSegmentDefType.chat) {
            out.push(renderChatSegment(segment, data));
        } else if (segment.type === PromptSegmentDefType.template) {
            out.push(renderTemplateSegment(segment, data));
        }
    }
    return out;
}

export function renderSegmentsOrErrors(segments: PromptSegmentDef<PromptTemplate>[], textOrObject: string | JSONObject) {
    try {
        return renderSegments(segments, typeof textOrObject === 'string' ? JSON.parse(textOrObject.trim()) : textOrObject || {});
    } catch (error: any) {
        //console.error(error);
        if (error instanceof CompositeError) {
            return error.errors.map(error => ({
                error: error as Error,
                title: "Rendering Error",
                content: error.message
            }));
        } else {
            return [{
                error: error as Error,
                title: "Rendering Error",
                content: error.message
            }];
        }
    }
}


export function renderTemplate(code: string, schema: JSONSchema4, data: JSONObject): string {
    const globals = schema.properties ? Object.keys(schema.properties) : [];
    return renderJsTemplate(code, globals, data);
}


export function renderPrompt(segments: PromptSegmentDef<PromptTemplate>[], payload: JSONObject): PromptSegment[] {
    const out: PromptSegment[] = [];
    for (const segment of segments) {
        if (segment.template) {
            const schema = segment.template.inputSchema || {};
            const content = renderTemplate(segment.template.content, schema, payload);
            out.push({ role: segment.template.role, content });
        } else if (segment.type === PromptSegmentDefType.chat) {
            const chatKey = segment.configuration?.chatKey ?? "chat";
            const messages = payload[chatKey] as unknown as PromptSegment[];
            if (!messages || !Array.isArray(messages)) {
                throw new Error("Chat prompt segment must have a messages array");
            }
            for (const msg of messages) {
                if (!msg.role) {
                    throw new Error("Chat prompt segment must have a role");
                }
                out.push({ role: msg.role, content: msg.content })
            }
        } else {
            throw new Error('Unknown prompt segment type: ' + segment.type);
        }
    }
    return out;
}
