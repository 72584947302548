import { Button, ButtonProps, ConfirmModal } from "@reactik/components";
import clsx from 'clsx';
import { useState } from "react";

interface DocumentsActionButtonProps extends Omit<ButtonProps, "onClick"> {
    doAction: (params?: any) => void;
    actionName?: string;
    params?: any;
    confirmationText: string;
    className?: string;
    isDisabled?: boolean;
}

export default function ConfirmActionButton(props: DocumentsActionButtonProps) {
    const { doAction, actionName, params, confirmationText, children, className } = props;
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const text = confirmationText || `Are you sure you want to ${actionName}?`;


    const onClick = () => {
        setShowConfirmModal(true);
    };
    const onConfirm = () => {
        setShowConfirmModal(false);
        doAction(params);
    };


    return (
        <div className="relative">
            <Button className={clsx(className, "")} onClick={onClick} {...props}>{children ?? actionName}</Button>
            <ConfirmModal onConfirm={onConfirm} onCancel={() => setShowConfirmModal(false)} title={"Are you sure?"} content={text} isOpen={showConfirmModal} />
        </div>
    );
}
