import { ExecutionRun } from "@becomposable/common";
import { JSONCode } from "@reactik/json";

interface PromptPanelProps {
    run: ExecutionRun | null | undefined;
}
export default function PromptPanel({ run }: PromptPanelProps) {
    return (
        run?.prompt ? <JSONCode data={run.prompt} /> : null
    )
}