import SelectAIModel from "@/modules/studio/features/environment/SelectAIModel";
import SelectOrCreateEnvironment from "@/modules/studio/features/environment/SelectOrCreateEnvironment";
import { useUserSession } from "@/session/UserSession";
import { ExecutionEnvironmentRef, Interaction, InteractionExecutionConfiguration } from "@becomposable/common";
import { Button, Input, Modal, ModalBody, ModalTitle, NumberInput, useToast } from "@reactik/components";
import { JSONCode } from "@reactik/json";
import { useEffect, useState } from "react";

interface GenerateTestDataModalProps {
    isOpen: boolean;
    onClose: () => void;
    interaction: Interaction;
    onSave: (data: any) => void;
}

export default function GenerateTestDataModal({ isOpen, onClose, interaction, onSave }: GenerateTestDataModalProps) {

    const { client } = useUserSession();
    const [model, setModel] = useState<string | undefined>(interaction.model);
    const [envs, setEnvs] = useState<ExecutionEnvironmentRef[]>([]);
    const [selectedEnv, setSelectedEnv] = useState<ExecutionEnvironmentRef | undefined>(undefined);
    const [testData, setTestData] = useState<any[]>([]);
    const [count, setCount] = useState<number | undefined>(1);
    const [message, setMessage] = useState<string>("");
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        client.environments.list().then((res) => {
            setEnvs(res);
            setSelectedEnv(res.find((env) => env.id === interaction.environment));
        }).catch((err) => {
            console.error(err);
            toast({
                title: "Error",
                description: err.message,
                status: "error",
                duration: 3000,
            });
        });
    }, []);

    const generateTestData = () => {
        if (model && selectedEnv) {
            setIsLoading(true);
            setTestData([]);
            const config: InteractionExecutionConfiguration = {
                environment: selectedEnv.id,
                model: model,
            };

            client.interactions.generateTestData(interaction.id, { config, message, count }).then((res) => {
                setTestData(res);
            }).catch((err) => {
                console.error(err);
                toast({
                    title: "Error",
                    description: err.message,
                    status: "error",
                    duration: 3000,
                });
            })
                .finally(() => setIsLoading(false));

        } else {
            toast({
                title: "Please select an environment and a model",
                description: "Please select an environment and a model",
                status: "warning",
                duration: 3000,
            });
        }
    };

    const selectTestData = (data: any) => {
        onSave(data);
        setTestData([]);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} className='min-w-[80vw]'>
            <ModalTitle>Generate Synthetic Data</ModalTitle>
            <ModalBody className='p-4 pt-0 overflow-y-auto max-h-[80vh] min-h-[80vh]'>
                <div className="flex flex-row gap-4 py-4">
                    <div className="flex-1">
                        <SelectOrCreateEnvironment value={selectedEnv} onChange={setSelectedEnv} />
                    </div>
                    <div className="flex-1">
                        <SelectAIModel size="sm" selected={model} onSelect={(m) => setModel(m?.id)} models={selectedEnv?.enabled_models} />
                    </div>
                    <div className="flex-1">
                        <NumberInput value={count} onChange={setCount} />
                    </div>
                </div>

                <div className="flex flex-row gap-4 w-11/12">
                    <div className="flex-1">
                        <Input value={message} onChange={setMessage} placeholder="Hint(s) for the generation" size={10} />
                    </div>
                    <Button isDisabled={isLoading} isLoading={isLoading} onClick={generateTestData}>Generate</Button>
                </div>

                <div className="w-full">
                    <h3 className="pt-8">Test Data</h3>
                    {testData?.map((data, i) => (
                        <div key={i} className="w-full pt-4 flex gap-2">
                            <div className="w-full h-[60vh] overflow-y-scroll"><JSONCode data={data} /></div>
                            <div>
                                <Button variant="primary" onClick={() => selectTestData(data)}>Save</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </ModalBody>
        </Modal>

    );


}
