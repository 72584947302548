import { useUserSession } from "@/session/UserSession";
import { useParams } from "@reactik/router";
import PublicInteractionView from "./PublicInteractionView";
import PublicPage from "./PublicPage";

export default function PublicInteractionPage() {
    const { user, isLoading } = useUserSession();
    const { interactionId } = useParams();
    return !isLoading && (
        <PublicPage>
            {
                <PublicInteractionView interactionId={interactionId as string} />
            }
        </PublicPage>
    );
}
