import { MouseEvent, useEffect, useState } from 'react';

import { useUserSession } from '@/session/UserSession';
import { ExecutionEnvironment, SupportedProviders, SupportedProvidersList } from '@becomposable/common';
import { AIModel, ModelSearchPayload } from '@llumiverse/core';
import { Button, Input, Spinner } from '@reactik/components';

import { AIModelEntry } from './SelectAIModel';

export function BrowseAndAddModels({ env, onAddModel, listClassName }: { env: ExecutionEnvironment; onAddModel: (model: AIModel) => void; listClassName?: string; }) {

    const { client } = useUserSession();
    const [models, setModels] = useState<AIModel[]>([]);
    const [params, setParams] = useState<ModelSearchPayload>({ text: '' });
    const [searchText, setSearchText] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const canSearch = SupportedProvidersList[env.provider]?.supportSearch ?? undefined;
    const availableModels = canSearch
        ? models
        : models.filter(model => model.name.includes(searchText) || model.description?.includes(searchText));

    const onSearch = (e: MouseEvent<HTMLButtonElement>) => {
        if (searchText) {
            setParams({ ...params, text: searchText });
        } else {
            return;
        }
    };

    useEffect(() => {
        if (canSearch) {
            setIsLoading(true);
            client.environments.listModels(env.id, params)
                .then(setModels)
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(true);
            client.environments.listModels(env.id)
                .then(setModels)
                .finally(() => setIsLoading(false));
        }
    }, [params]);

    const searchHelp: Record<SupportedProviders, string> = {
        [SupportedProviders.openai]: "type to filter models by name",
        [SupportedProviders.azure_openai]: "type to filter models by name",
        [SupportedProviders.replicate]: "<model-owner>/<model-name>",
        //[SupportedProviders.cohere]: "",
        [SupportedProviders.huggingface_ie]: "",
        [SupportedProviders.bedrock]: "type to filter models by name",
        [SupportedProviders.vertexai]: "type to filter models by name",
        [SupportedProviders.togetherai]: "type to filter models by name",
        [SupportedProviders.mistralai]: "type to filter models by name",
        [SupportedProviders.groq]: "type to filter models by name",
        [SupportedProviders.watsonx]: "type to filter models by name",
        [SupportedProviders.virtual_lb]: "type to filter models by name",
        [SupportedProviders.virtual_mediator]: "type to filter models by name",
        [SupportedProviders.test]: "type to filter models by name",
    };

    return (
        <>
            <div className="pb-4 pr-4">
                <h2 className="text-xl font-semibold pb-2">Available Models</h2>
                <div className="text-gray-400 dark:text-slate-600">
                    Click on add to enable models in this environment.
                </div>
                <div className="flex items-center space-x-2">
                    <Input className="px-4 py-1 border border-gray-300 rounded-md"
                        value={searchText}
                        onChange={(setSearchText)}
                        placeholder={searchHelp[env.provider]} />
                    {canSearch && <Button variant="secondary" isLoading={isLoading} onClick={onSearch}>Search</Button>}
                </div>
            </div>
            <div className={`${listClassName}`}>
                <div className="flex flex-col">
                    {isLoading && <Spinner className="mx-auto" />}
                    {availableModels.map(model => {
                        return (
                            <AIModelEntry key={model.id} model={model} onAddModel={onAddModel} />
                        );
                    })}
                </div>
            </div >
        </>
    );


}
