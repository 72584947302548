export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node;
    } else {
        return getScrollParent(node.parentNode as HTMLElement | null);
    }
}

export function scrollToBottom(el: HTMLElement) {
    el.scrollTop = el.scrollHeight;
}