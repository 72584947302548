import { useUserSession } from "@/session/UserSession";
import { GithubConfiguration, SupportedIntegrations } from "@becomposable/common";
import { Button, Input, Spinner, Switch, useToast } from "@reactik/components";
import { useFetch } from "@reactik/hooks";


export default function GithubSettings() {
    const { client, project } = useUserSession();
    const toast = useToast();

    const { data: config, refetch, isLoading, setData } = useFetch(() => client.projects.integrations.retrieve(project!.id, SupportedIntegrations.github) as Promise<GithubConfiguration>, [project])

    const defaultConfig: GithubConfiguration = {
        enabled: false,
        allowed_repositories: []
    }

    const onSave = () => {
        client.projects.integrations.update(project!.id, SupportedIntegrations.github, config).then(() => {
            toast({
                title: 'Settings saved',
                status: 'success'
            });
            refetch();
        }).catch((e) => {
            toast({
                title: 'Failed to save settings',
                status: 'error'
            });
        });
    }

    const parseRepositories = (value: string) => {
        return value.split(',').map(v => v.trim())
    }

    return (
        <div key="github" className="">
            <h3 className="text-lg font-semibold py-2">GitHub</h3>
            <p className="pb-4 text-sm">GitHub integration enables to authenticate Github's repositories to your composable account.</p>

            {isLoading ?
                <Spinner />
                :
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4 align-middle">
                        <label className="text-nowrap">Enabled</label>
                        <Switch value={config?.enabled ?? false} onChange={(value) => setData({ ...config ?? defaultConfig, enabled: value })} />
                    </div>
                    <div className="flex flex-col gap-2 align-middle">
                        <label className="text-nowrap">Allowed Repositories</label>
                        <Input className="w-full"
                            value={config?.allowed_repositories.join(', ')}
                            onChange={(value) => setData({ ...config ?? defaultConfig, allowed_repositories: parseRepositories(value) })}
                        />
                    </div>
                    <Button onClick={onSave}>Save</Button>
                </div>
            }
        </div>

    )
}