import { Property, createCompositeStateProvider } from "@reactik/hooks";

export default class InteractionViewState {
    //save = new Slot();
    save = new Property<(() => Promise<unknown>) | undefined>()
    isDirty = new Property(false);
    static Provider = createCompositeStateProvider(InteractionViewState);

    constructor(public canEdit: boolean) {
    }

    saveChanges() {
        const saveImpl = this.save.value;
        if (saveImpl) {
            return saveImpl().then(() => {
                this.isDirty.value = false;
            });
        } else {
            console.error('No save implementation found');
            return Promise.resolve();
        }
    }
}
