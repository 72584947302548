import clsx from "clsx";


interface DividerProps {
    className?: string;
}
export function Divider({ className }: DividerProps) {
    return (
        <hr className={clsx("w-full h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50", className)} />
    )
}
