import PanelErrorBoundary from "@/errors/PanelErrorBoundary";
import WidgetErrorBoundary from "@/errors/WidgetErrorBoundary";
import { FullHeightLayout } from "@reactik/layout";
import { ReactNode } from "react";

interface EditorLayoutProps {
    title?: string;
    actions?: ReactNode | ReactNode[];
    children: ReactNode;
}
export default function EditorLayout({ title, actions, children }: EditorLayoutProps) {
    return (
        <FullHeightLayout className="p-2 overflow-hidden rounded-lg shadow dark:shadow-none bg-white dark:bg-slate-900 dark:border dark:border-slate-600">
            <div className="flex items-center h-10 py-2">
                <div className="text-lg font-semibold">{title}</div>
                {actions &&
                    <div className="ml-auto flex gap-x-2 items-center">
                        <WidgetErrorBoundary>{actions}</WidgetErrorBoundary>
                    </div>
                }
            </div>
            <div className="flex-1 overflow-auto mt-2">
                <PanelErrorBoundary>{children}</PanelErrorBoundary>
            </div>
        </FullHeightLayout>
    )
}