import { GithubAuthProvider, signInWithRedirect } from "firebase/auth";
import { firebaseAuth } from "../../session/auth/firebase";

interface GitHubSignInButtonProps {
    redirectTo?: string;
}
export default function GitHubSignInButton({ }: GitHubSignInButtonProps) {
    const signIn = () => {
        //with github can only have one allowed redirect
        const baseUrl = "https://dengenlabs.firebaseapp.com/__/auth/handler";
        let redirectPath = baseUrl + window.location.pathname;
        if (redirectPath[0] !== "/") {
            redirectPath = "/" + redirectPath;
        }
        const provider = new GithubAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        /*provider.setCustomParameters({
            redirect_uri: redirectPath,
        });*/
        signInWithRedirect(firebaseAuth, provider);
    };

    return (
        <button
            onClick={signIn}
            className="my-2 flex w-52 gap-2 rounded-lg border border-slate-200 px-4 py-2 text-slate-700 transition duration-150  dark:text-slate-300 hover:border-slate-400 hover:text-slate-900 dark:hover:text-slate-200 hover:shadow"
        >
            <img
                className="h-6 w-6"
                src="https://www.svgrepo.com/show/503359/github.svg"
                loading="lazy"
                alt="github logo"
            />
            <span className="text-sm font-semibold">Login with GitHub</span>
        </button>
    );
}
