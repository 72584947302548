import { AcesTable } from '@/features/settings/iam/AcesTable';
import { useUserSession } from '@/session/UserSession';
import { AccessControlEntry, AccessControlledResource, ResolvableRefType } from '@becomposable/common';
import { useToast } from '@reactik/components';

import { useAccessControlEntryRegistry } from './AccessControlEntryRegistry';

export default function AcesView() {
    const registry = useAccessControlEntryRegistry();
    const { client, account } = useUserSession();
    const toast = useToast()

    const onDelete = (ace: AccessControlEntry) => {
        client.iam.aces.delete(ace.id).then(() => {
            toast({
                status: "success",
                title: "Access Control Entry deleted"
            })
            registry.refresh();
        }).catch((err) => {
            console.error(err)
            toast({
                status: "error",
                title: "Cannot delete this Access Control Entry",
                description: err.message
            })
        })
    }

    return (
        <AcesTable aces={registry.aces} refPool={registry.refs} isLoading={registry.isLoading} onDelete={onDelete} />
    )
}



export function convertToResolvableRefType(type: AccessControlledResource) {

    switch (type) {
        case AccessControlledResource.account: return ResolvableRefType.account
        case AccessControlledResource.environment: return ResolvableRefType.environment
        case AccessControlledResource.interaction: return ResolvableRefType.interaction
        case AccessControlledResource.project: return ResolvableRefType.project
    }

}
