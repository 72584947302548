import Env from '@/env.ts';
import { datadogRum } from '@datadog/browser-rum';
import { reactPlugin } from '@datadog/browser-rum-react';
import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';


if (Env.sentry.dsn) {
  Sentry.init({
    enabled: Env.sentry.dsn ? true : false,
    dsn: Env.sentry.dsn,
    environment: Env.type,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: Env.isProd ? 0.3 : 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/.+\.composableprompts.+\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  console.log('Sentry disabled');
}

if (Env.datadog) {
  datadogRum.init({
    applicationId: 'abb2e6b5-6696-464d-b659-76e9e355b56d',
    clientToken: 'pub479254b982154a0ef8aa6f7eba9aa968',
    site: 'us3.datadoghq.com',
    service: 'composable-ui',
    env: Env.type,
    version: Env.version,
    sessionSampleRate: Env.isProd ? 30 : 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    betaPlugins: [reactPlugin()],
  });
} else {
  console.log('Datadog RUM disabled');
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
