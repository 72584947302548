import { shortenString } from "@/modules/store/text";
import { useUserSession } from "@/session/UserSession";
import { AccessControlledResource, ProjectRef, ProjectRoles, User } from "@becomposable/common";
import { Button, SelectList, SelectListProps, useToast } from "@reactik/components";
import { useFetch, useWatchSharedState } from "@reactik/hooks";
import clsx from "clsx";
import { useState } from "react";
import { useRoleSelection } from "./RoleSelectionContext";
import { RoleSelectionProvider } from "./RoleSelectionProvider";

interface SelectSectionProps<T> extends SelectListProps<T> {
    type: 'user' | 'context' | 'role';
    title: string;
}
function SelectSection<T>({ title, type, onChange, ...others }: SelectSectionProps<T>) {
    const state = useRoleSelection();
    useWatchSharedState(state);
    const _onChange = (value: T) => {
        state.value = state.value.setValue(type, value);
        onChange && onChange(value)
    }
    const onSelect = () => {
        state.value = state.value.select(type);
    }
    const isOpen = state.value.selected === type;
    const titleValue = state.value.renderValue(type);
    return (
        <div className="border border-gray-200 dark:border-slate-500 rounded-md">
            <div className={clsx('flex gap-x-2 cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-slate-800', isOpen && 'bg-gray-100 dark:bg-slate-800')} onClick={onSelect}>
                <div className="font-semibold text-sm">{title}{titleValue ? ':' : ''}</div>
                <div className="text-sm text-indigo-500">{titleValue}</div>
            </div>
            {isOpen &&
                <div className="h-64 overflow-y-scroll">
                    <SelectList onChange={_onChange} {...others} />
                </div>
            }
        </div>
    )
}

const userLabel = (u: User) => {
    const totalLen = 25
    const rest = totalLen - u.name.length
    return {
        label: (
            < div className="flex flex-col" >
                <span className="text-sm">{u.name} ({shortenString(u.email, rest)})</span>
            </div >
        )
    }
}
const projectLabel = (p: ProjectRef) => {
    return p.id.startsWith("account:") ? {
        label: "Organization: " + p.name
    } : {
        label: "Project: " + p.name
    }
}


export default function SetRoleComponent({ onDone }: { onDone?: () => unknown }) {

    const { client, account } = useUserSession();
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
    const [selectedRole, setRole] = useState<ProjectRoles | undefined>(undefined)
    const [selectedProject, setSelectedProject] = useState<ProjectRef | undefined>(undefined)
    const toast = useToast();


    const { data: members, isLoading } = useFetch(() => {
        return client.account.members()
    }, [account])

    const { data: projects } = useFetch(() => {
        return client.projects.list(account ? [account.id] : undefined)
    }, [account]);

    const onAdd = () => {
        if (!selectedRole) {
            toast({ status: "warning", title: "Select a role", duration: 300 });
            return
        }

        if (!selectedUser) {
            toast({ status: "warning", title: "Select a user", duration: 300 });
            return
        }

        if (!selectedProject) {
            toast({ status: "warning", title: "Need an account or a project selected", duration: 300 });
            return
        }

        let resource: string = selectedProject.id;
        let type: AccessControlledResource = AccessControlledResource.project;
        if (resource.startsWith("account:")) {
            resource = selectedProject.account;
            type = AccessControlledResource.account;
        }

        client.iam.aces.create({
            principal: selectedUser.id,
            role: selectedRole,
            resource,
            type
        }).then(() => {
            toast({
                status: "success", title: "Role added",
                duration: 200,
                description: `Role ${selectedRole} added to ${selectedUser.name} on ${selectedProject?.name}`
            })
            onDone?.()
        });

    }


    const projectsOrOrgs: ProjectRef[] = (account ? [{
        id: 'account:' + account.id,
        name: account.name,
        account: account.id
    }] : []).concat(projects || [])

    return (
        <RoleSelectionProvider>
            <div className="flex flex-col gap-2">
                <SelectSection type="context"
                    by="id"
                    title="Select a project or organization"
                    options={projectsOrOrgs}
                    value={selectedProject}
                    optionLayout={projectLabel}
                    onChange={setSelectedProject} />
                <SelectSection type="user"
                    title="Select a user"
                    by="id"
                    value={selectedUser}
                    options={members || []}
                    onChange={setSelectedUser}
                    optionLayout={userLabel}
                />
                <SelectSection type="role"
                    title="Select a role"
                    options={Object.values(ProjectRoles)}
                    onChange={setRole}
                    value={selectedRole}
                />
            </div>
            <div className="flex justify-end -ml-4 -mr-4 -mb-4 mt-4 p-4 bg-gray-50 dark:bg-transparent border-t border-t-gray-100 dark:border-t-slate-500">
                <Button size='lg' isDisabled={!selectedRole || !selectedProject || !selectedUser} onClick={onAdd}>Set Role</Button>
            </div>
        </RoleSelectionProvider>
    )


}
