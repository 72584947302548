import { AppLayout } from '@reactik/layout';
import { AppMenu } from './AppMenu';
import PanelErrorBoundary from './errors/PanelErrorBoundary';
import UserLogin from './features/login/UserLogin';
import Env from './env';


// export function AppLogo() {
//     return <div className='text-lg text-white'>Composable</div>
// }

interface AppPageProps {
    title?: string;
    children: React.ReactNode | React.ReactNode[]
    className?: string;
}
export default function AppPage({ children, title, className }: AppPageProps) {
    // in preview we add an eye iconon the right of the tenant. See UserLogin.tsx
    const sidebarBg = Env.isDev && !Env.isPreview ? 'bg-green-800 dark:bg-green-900' : undefined;
    return (
        <AppLayout title={title} logo={<UserLogin />} sidebar={<AppMenu />} className={className} sidebarClassName={sidebarBg}>
            <PanelErrorBoundary>
                <PageContent>{children}</PageContent>
            </PanelErrorBoundary>
        </AppLayout>
    )
}

interface PageContentProps {
    children: React.ReactNode | React.ReactNode[]
}
function PageContent({ children }: PageContentProps) {
    //TODO you can use this to show a loading spinner or to switch pages on authentication flow.
    return <>{children}</>
}
