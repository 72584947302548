import { trunc } from "@/modules/studio/utils/string";
import { ExecutionRun } from "@becomposable/common";
import { ErrorBox } from "@reactik/components";
import { JSONDisplay } from "@reactik/json";

interface ResultPanelProps {
    run: ExecutionRun | null | undefined;
}
export default function ResultPanel({ run }: ResultPanelProps) {
    if (!run) {
        return null;
    } else {
        return <div>
            <_ResultPanel run={run} />
            <div className="py-2 text-sm text-gray-700 dark:text-gray-300">by <span className="font-semibold">{trunc(run.modelId, 32)}</span> in {(run.execution_time || 0) / 1000}s</div>
        </div>
    }
}


interface _ResultPanelProps {
    run: ExecutionRun;
}
function _ResultPanel({ run }: _ResultPanelProps) {
    if (run.error) {
        return <ErrorBox title="Failed to run interaction">
            <pre className="whitespace-pre-wrap">{run.error.message}</pre>
            <div>{run.error.data || ''}</div>
        </ErrorBox>;
    } else if (typeof run.result === 'string') {
        return <pre className="whitespace-pre-wrap">{run?.result as string}</pre>;
    } else if (run.result) {
        return <JSONDisplay value={run.result} />;
    } else {
        return null;
    }
}