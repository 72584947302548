const env = import.meta.env;

const isLocalDev = window.location.host.includes('localhost');

//location: https://preview.cloud.becomposable.com
const host = window.location.host.split('.');
const isProd = host.length === 3 && host[0] === "cloud";
const backendBase = env.VITE_API_BACKEND_BASE;


const getEnv = () => {

    if (isProd) {
        return "production";
    }

    if (isLocalDev) {
        return "development";
    }

    if (window.location.host.startsWith("preview")) {
        return "preview";
    }

    if (window.location.host.startsWith("staging")) {
        return "staging";
    }

    if (window.location.host.endsWith("composable.sh")) {
        const re= /.+git\-([a-z0-9\-]+)\.composable\.sh/;
        const match = window.location.host.match(re);
        console.log('Match', match);
        if (match) {
            return 'dev-' + match[1];
        }
    }

}

const envType = getEnv();
if (!envType) {
    throw new Error('Could not determine environment type');
} else {
    console.log('Environment is:', envType);
}


const getServerUrl = (service: string, env: string) => {
    //const params = useParams();

    if (isLocalDev) {
        if (service === "studio") {
            return `http://127.0.0.1:8091`;
        }
        if (service === "zeno") {
            return `http://127.0.0.1:8092`;
        }
    }

    if (!backendBase) {
        throw new Error('VITE_API_BACKEND_BASE not set');
    }

    return `https://${service}-server-${env}.${backendBase}`;

};

const getBaseUrl = () => {

    if (env.BASE_URL) {
        return env.BASE_URL
    }

    return `https://${window.location.host}`;

}

function getAuthDomain() {
    if (isLocalDev && typeof window !== 'undefined') {
        // use current host since vite may change the port
        console.log('Using local dev auth domain:', window.location.host);
        return window.location.host;
    }

    return window.location.host;
}

const authDomain = getAuthDomain();

let isDatadogRumEnabled;

if (env.DATADOG_RUM_ENABLED) {
    // override with env var always take precedence
    isDatadogRumEnabled = env.DATADOG_RUM_ENABLED === "true";
} else if (isLocalDev) {
     // disable RUM by default in local dev
    isDatadogRumEnabled = false;
} else {
     // enable RUM by default in cloud envs
    isDatadogRumEnabled = true;
}

const Env = {
    version: __VERCEL_ENV_COMMIT_SHA__,
    isProd: isProd,
    isDev: !isProd,
    isPreview: envType === "preview",
    isLocalDev,
    type: envType,
    BASE_URL: getBaseUrl(),
    endpoints: {
        zeno: getServerUrl('zeno', envType),
        studio: getServerUrl('studio', envType),
    },
    API_PATH: `/api/v1`,
    firebase: {
        apiKey: env.VITE_FIREBASE_API_KEY,
        authDomain,
        projectId: "dengenlabs",
    },
    sentry: {
        dsn: env.VITE_SENTRY_DSN
    },
    datadog: isDatadogRumEnabled,
};

if (!Env.isProd) {
    console.log("Initializing App with Env", Env);
}

export default Env;