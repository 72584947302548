import { contentToJSON, jsonToContent } from "@/modules/studio/utils/test-data";
import { json } from "@codemirror/lang-json";
import type { JSONObject } from "@llumiverse/core";
import { CodeMirrorEditor, EditorApi } from "@reactik/codemirror";
import { Button, Modal, ModalBody, ModalFooter, ModalTitle, useToast } from "@reactik/components";
import { basicSetup } from "codemirror";
import { useRef } from "react";

const extensions = [basicSetup, json()]

interface JSONEditorModalProps {
    title: string;
    value?: JSONObject;
    isOpen?: boolean
    // if value is undefined then do not save changes
    onClose: (value?: JSONObject) => void;
}
export default function JSONEditorModal({ title, value, isOpen, onClose }: JSONEditorModalProps) {

    const editorRef = useRef<EditorApi | undefined>(undefined);
    const toast = useToast();

    const onSaveChanges = () => {
        if (editorRef.current) {
            const value = editorRef.current.getValue();
            try {
                const newValue = contentToJSON(value);
                onClose(newValue);
            } catch (err: any) {
                toast({
                    status: 'error',
                    title: 'Invalid JSON Schema',
                    description: err.message,
                    duration: 5000
                })
            }
        }
    }

    return (
        <Modal onClose={() => onClose()} isOpen={!!isOpen} className='min-w-[60vw]'>
            <ModalTitle>{title}</ModalTitle>
            <ModalBody className='p-4 overflow-y-auto max-h-[80vh]'>
                <CodeMirrorEditor value={jsonToContent(value)} extensions={extensions} editorRef={editorRef} />
            </ModalBody>
            <ModalFooter justify="end">
                <Button onClick={onSaveChanges}>Save changes</Button>
            </ModalFooter>
        </Modal>
    )
}
