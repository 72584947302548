import AcesView from '@/features/settings/iam/AcesView';
import { InviteUserToOrgWidget } from '@/features/settings/iam/InviteUserToOrg';
import SettingsPanel from '@/features/settings/SettingsPanel';
import { PermissionsTitle } from '@/modules/studio/features/project/RolesMappingModal';

import AccessControlEntryProvider from './AccessControlEntryProvider';

export default function OrgRolesView() {

    return (
        <AccessControlEntryProvider>
            <div className="w-full">
                <SettingsPanel title={"Invite Users"}>
                    <InviteUserToOrgWidget />
                </SettingsPanel>

                <SettingsPanel title={<PermissionsTitle />}>
                    <AcesView />
                </SettingsPanel>
            </div>
        </AccessControlEntryProvider>
    )

}
