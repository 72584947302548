import clsx from 'clsx';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useMemo, useState } from 'react';

import ConfirmActionButton from '@/modules/store/features/store/objects/ConfirmActionButton';
import { AccessControlEntry, ResourceRef, UserRef } from '@becomposable/common';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Input, Table, TBody } from '@reactik/components';

dayjs.extend(LocalizedFormat);

interface Ace {
    ace: AccessControlEntry;
    principal: ResourceRef;
    resource: ResourceRef;
    principalText: string;
    resourceText: string;
    type: string;
}

interface AcesTableProps {
    aces: AccessControlEntry[] | undefined
    isLoading?: boolean
    refPool: ResourceRef[] | undefined
    onDelete: (ace: AccessControlEntry) => void
}

export function AcesTable({ aces, isLoading, refPool, onDelete }: AcesTableProps) {
    const [{ userQuery, resourceQuery }, setFilter] = useState({ userQuery: '', resourceQuery: '' });
    const onFilterChange = (user: string, resource: string) => {
        setFilter({
            userQuery: user.toLowerCase(), resourceQuery: resource.toLowerCase()
        })
    }

    const entries: Ace[] = useMemo(() => {
        if (refPool && aces) {
            return aces.reduce((result: Ace[], ace: AccessControlEntry) => {
                const principal = refPool.find(r => r.id === ace.principal) || { name: "Unknown", id: ace.principal } as ResourceRef;
                const resource = refPool.find(r => r.id === ace.resource) || { name: "Unknown", id: ace.resource } as ResourceRef;
                let type: string;
                if (!resource) {
                    type = "Unknown";
                } else if ((resource as any).account) {
                    type = "Project";
                } else {
                    type = "Organization";
                }

                result.push({
                    ace,
                    principal,
                    resource,
                    principalText: principal.name.toLowerCase(),
                    resourceText: resource.name.toLowerCase(),
                    type
                });
                return result;
            }, [] as Ace[]);
        } else {
            return [];
        }
    }, [aces, refPool])

    const filteredEntries = resourceQuery || userQuery ? entries.filter(entry => {
        if (resourceQuery) {
            if (!entry.resourceText.includes(resourceQuery)) return false;
        }
        if (userQuery) {
            if (!entry.principalText.includes(userQuery)) return false;
        }
        return true;
    }) : entries;

    return (
        <div className='border-t border-t-gray-200 pt-4'>
            <FilterBox onChange={onFilterChange} />
            <Table className="w-full">
                <thead>
                    <tr>
                        <td>Principal</td>
                        <td>Role</td>
                        <td>Resource</td>
                        <td>Type</td>
                        <td>Created At</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <TBody columns={5} isLoading={isLoading} >
                    {filteredEntries.map((entry) => {
                        return (<tr key={entry.ace.id}>
                            <td className="flex flex-col">
                                <ResourceInfo resource={entry.principal} />
                            </td>
                            <td>{entry.ace.role}</td>
                            <td><ResourceInfo resource={entry.resource} /></td>
                            <td>{entry.type}</td>
                            <td>{dayjs(entry.ace.created_at).format('LL')}</td>
                            <td><ConfirmActionButton
                                variant="ghost"
                                doAction={onDelete}
                                params={entry.ace}
                                confirmationText={`Are you sure you want to delete this role?`}
                            ><TrashIcon className="w-5 h-5" />
                            </ConfirmActionButton></td>
                        </tr>)
                    }
                    )}
                </TBody>
            </Table>
        </div>
    )


}

interface ResourceInfoProps {
    resource: any
    className?: string
}

function ResourceInfo({ resource, className }: ResourceInfoProps) {

    const label = resource ? resource.name : "Unknown";
    if (resource && resource.email) {
        return <div className="flex flex-col">
            <span className={clsx("text-sm", className)}>{label}</span>
            <span className={clsx("text-xs", className)}>{resource.email}</span>
        </div>
    }

    return <span className={clsx("text-sm", className)}>{label}</span>
}

interface FilterBoxProps {
    onChange: (user: string, resource: string) => void
}
function FilterBox({ onChange }: FilterBoxProps) {
    const [user, setUser] = useState('');
    const [resource, setResource] = useState('');
    const onChangeUser = (value: string) => {
        setUser(value);
        onChange(value, resource);
    }
    const onChangeResource = (value: string) => {
        setResource(value);
        onChange(user, value);
    }
    return (
        <div className='flex gap-x-2'>
            <Input placeholder="Filter by user" value={user} onChange={onChangeUser} />
            <Input placeholder="Filter by resource" value={resource} onChange={onChangeResource} />
        </div>
    )
}