import { ExecutionEnvironment } from "@becomposable/common";
import { AIModel } from "@llumiverse/core";
import { Button, Modal, ModalBody, ModalTitle, SelectBox } from "@reactik/components";
import { useEffect, useState } from "react";
import { BrowseAndAddModels } from "./BrowseAndAddModels";
import ModelIdBox from "./ModelIdBox";


interface SelectAIModelProps {
    onSelect: (model: AIModel | undefined) => void;
    models?: AIModel[];
    selected?: string | undefined;
    allowSearch?: boolean;
    env?: ExecutionEnvironment;
    size?: "sm" | "lg";
}

export default function SelectAIModel({ models, onSelect, selected, env, allowSearch = false, size = 'lg' }: SelectAIModelProps) {

    const [selectedModel, setSelectedModel] = useState<AIModel | undefined>(undefined);
    const [showSearchModal, setShowSearchModal] = useState(false);

    useEffect(() => {
        if (!selected) {
            setSelectedModel(undefined);
        } else if (models) {
            const model = models.find(m => m.id === selected) ?? undefined;
            setSelectedModel(model);
        }
    }, [selected, models]);

    const modelOption = (m: AIModel | undefined) => (
        m && <div className="flex flex-col">
            <div className="w-full font-semibold">{m.name}</div>
            {size === 'lg' &&
                <>
                    {m.description && <div className="w-full text-xs">{m.description}</div>}
                    < div className="text-xs">ID: {m.id}</div>
                </>
            }
        </div>
    );

    const onSelectFromSearch = (model: AIModel | undefined) => {
        if (!model) return;
        models?.push(model);
        setSelectedModel(model);
        onSelect(model);
        setShowSearchModal(false);
    };

    return (
        <div>
            <SelectBox
                options={models}
                optionLabel={modelOption}
                value={selectedModel}
                onChange={onSelect}
                placeholder="Select a model"
                addNew={allowSearch ? () => setShowSearchModal(true) : undefined}
                addNewLabel={allowSearch ? "Search for a model" : undefined} />
            {(env && allowSearch) &&
                <SelectAIModelModal
                    env={env}
                    isOpen={showSearchModal}
                    onClose={() => setShowSearchModal(false)}
                    onSelect={onSelectFromSearch} />}
        </div>
    );
}

interface AIModelEntryProps {
    model: AIModel;
    onAddModel?: (model: AIModel) => void;
    onRemoveModel?: (model: AIModel) => void;
}
export function AIModelEntry({ model, onAddModel, onRemoveModel }: AIModelEntryProps) {

    const actionLabel = onAddModel ? "Add" : "Remove";
    const action = (m: AIModel) => {
        if (onAddModel) {
            console.log("Adding model", m);
            onAddModel(m);
        } else if (onRemoveModel) {
            console.log("Removing model", m);
            onRemoveModel(m);
        } else {
            console.error("No action handler provided");
        }
    };

    return (<div className="flex flex-col mb-6">
        <div className="w-full flex gap-x-2 items-center">
            <span className="">{model.name}</span>
            {action &&
                <Button className='ml-auto' variant="primary" size="xs" onClick={() => action(model)}>{actionLabel}</Button>}
        </div>
        <ModelIdBox model={model} />
        {model.description && <div className="text-sm pt-1 text-gray-600 dark:text-slate-200">{model.description}</div>}
    </div>);
}


interface SelectAIModelModalProps {
    isOpen: boolean;
    onClose: () => void;
    env: ExecutionEnvironment;
    onSelect: (model: AIModel | undefined) => void;
}
function SelectAIModelModal({ isOpen, onClose, env, onSelect }: SelectAIModelModalProps) {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalTitle>Select a Model</ModalTitle>
            <ModalBody>
                <BrowseAndAddModels env={env} onAddModel={onSelect} listClassName="h-[50vh] overflow-y-auto"></BrowseAndAddModels>
            </ModalBody>
        </Modal>
    );

}
