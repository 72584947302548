import { ReactNode, useMemo } from "react";
import { RoleSelection, RoleSelectionContext } from "./RoleSelectionContext";
import { SharedState } from "@reactik/hooks";

interface RoleSelectionProviderProps {
    children: ReactNode
}
export function RoleSelectionProvider({ children }: RoleSelectionProviderProps) {
    const context = useMemo(() => new SharedState(new RoleSelection()), []);
    return (
        <RoleSelectionContext.Provider value={context}>
            {children}
        </RoleSelectionContext.Provider>
    )
}
